@font-face {
  font-family: Roboto;
  src: url(/public/font/RobotoCondensed-Regular.ttf);
}

@font-face {
  font-family: Roboto;
  src: url(/public/font/RobotoCondensed-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: Inter;
  src: url(/public/font/Inter-Regular.otf);
}

@font-face {
  font-family: Nunito;
  src: url(/public/font/Nunito-Regular.ttf);
}

@font-face {
  font-family: Gilroy;
  src: url(/public/font/Gilroy-ExtraBold.otf);
}

/* * {
    background-color: rgba(0, 150, 0, 0.1) !important;
} */

body {
    margin: 0px;
    font-family: Gilroy;
    color: #434343;
}

.bg {
    background: #EAEAEA;
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: auto;
}

.bg.kitchen {
    background: linear-gradient(139.89deg, #715026 -87.35%, #876737 -76.82%, #C8AB68 -42.57%, #F0D586 -16.23%, #FFE592 -3.06%, #BD9D54 57.52%, #A2803B 86.5%, #A4833E 112.84%, #AB8C48 131.28%, #B79C58 149.72%, #C8B170 168.16%, #D2BF7E 176.06%);
}

.bg.cellar {
    background: linear-gradient(72.22deg, #C5CACE -54.37%, #A5A9AC -48.65%, #8C8F92 -40.07%, #838688 -37.21%, #898C8F -25.76%, #919699 -14.32%, #A4ABB0 11.43%, #BBC3CA 34.31%, #BAC3C8 62.92%, #BDC5CA 68.64%, #D9D9D9 102.97%, #DFDEDE 108.69%, #F1EDED 117.27%, #FFF9F9 120.13%, #F5F5F4 140.16%, #FFFFFF 151.6%, #F8F8F8 163.05%, #EAEAEA 171.63%, #CBCBCD 200.23%, #C3CCD3 217.4%, #A4A9AC 228.84%);
}

.bg.farm,
.bg.garden {
    background: linear-gradient(92.33deg, #97F082 13.17%, #11BE67 94.97%); 
}

.bg.pet {
    background: linear-gradient(128.05deg, #FFB65A 31.25%, #FF7233 95.09%);
}

.bg.error {
    background: linear-gradient(92.33deg, #f17474 13.17%, #d52b2b 94.97%);
}

.error-text {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 56px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  background: linear-gradient(92.33deg, #ec8b8b 13.17%, #f65d5d 94.97%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;

  display: none;
}

.logo {
    height: 75px;
    width: 170px;
    margin-top: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
}

.error .logo, .loading .logo {
    /* background-image: url(/public/img/zikoland.png); */
    background-image: url(/public/img/zikoland.png);
}

.kitchen .logo {
    /* background-image: url(/public/img/kitchen.svg); */
    background-image: url(/public/img/color-logo-kitchen.svg);
}

.cellar .logo {
    /* background-image: url(/public/img/cellar.svg); */
    background-image: url(/public/img/color-logo-cellar.svg);
}

.garden .logo {
    /* background-image: url(/public/img/garden.svg); */
    background-image: url(/public/img/color-logo-garden.svg);
}

.farm .logo {
    /* background-image: url(/public/img/garden.svg); */
    background-image: url(/public/img/color-logo-farm.svg);
}

.pet .logo {
    /* background-image: url(/public/img/pet.svg); */
    background-image: url(/public/img/color-logo-pet.svg);
}

.mainwrap {
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%, 0%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.window {
    width: 335px;
    min-height: 60vh;
    box-sizing: border-box; 
    /* padding-bottom: 50px; */
    border-radius: 8px;
    background-color: #fff;
    padding: 25px 16px 25px 16px;
    margin-top: 25px;
    margin-bottom: 35px;

    display: flex;
    flex-direction: column;
    align-items: center;

}

.space {
    height: 94px;
}

.loading .window, .error .window {
    display: none;
}

.loader, .error-message {
    font-size: 24px;
    font-weight: bold;
    display: none;
    position: absolute;

    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
}

.loading .loader {
    display: block;
    color: #000000
}

.end-title {
    font-family: Roboto;
    margin: 0px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.6px;
    margin-top: 40px;
    margin-bottom: -10px;
}

.end-text {
    margin-top: 29px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    display: flex;
    align-items: center;
    text-align: center;
}

.error .error-message {
    display: block;
    color: #EEEEEE
}

.separator {
    height: 1px;
    width: 100%;
    background-color: #EEEEEE;
}

.order-id {
    width: 215px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400px;
    font-size: 14px;
    margin-top: 22px;
}

.order-id .label {
    font-weight: bold;
}

.order-id .value {
    font-weight: bold;
    font-family: Nunito;
}

.total-price {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Roboto;

    margin: 27px 0px 20px 0px;
}

.total-price .label {
    font-size: 16px;
    color: #888888;
}

.total-price .value {
    font-size: 24px;
    font-weight: bold;
}

.items-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 15px;
}

.item.wrap {
    display: flex;
    width: 100%;
    margin: 15px 0px;
}

.item.wrap .image {
    height: 80px;
    width: 80px;
    background-color: #ccc;
    border-radius: 3px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; 
}

.item.wrap .infos.wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 12px;
}

.infos.wrap .price.wrap .label {
    font-size: 11px;
}

.infos.wrap .name {
    margin-top: 11px;
    font-size: 14px;
}

.infos.wrap .qty {
    font-size: 12px;
    font-family: Nunito;
    color: #888888;
}

.infos.wrap .total {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
}

.infos.wrap .detail.wrap {
    flex: 1;
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.footer.wrap {
    bottom: 0px;
    position: fixed;
    height: 94px;
    width: 100vw;
    background-color: #fff;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);

    display: flex;
    justify-content: center;
    padding-top: 12px;
}

.footer.wrap .container {
    width: 335px;
    display: flex;
    flex-direction: row;
    padding: 0px;
    height: fit-content;
}

.footer.wrap .infos {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: end;
    padding-right: 15px;
}

.footer.wrap .label {
    font-family: Nunito;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
}

.footer.wrap .total {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 14px;
    margin-top: 3px;
}

.footer.wrap .pay-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 60px;
    gap: 10px;
    font-size: 16px;

    width: 174px;
    height: 39px;

    color: #fff;
    background: #07C160;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}


.footer.wrap .pay-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.fees.wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.fees.wrap .label {
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
}

.fees.wrap .amount {
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
}

/* -------------------------------------------------------- */

.bg.landing {
    width: unset;
    height: 100%;
    min-height: 100vh;

    background: #11141B !important;

    display: flex;
    justify-content: center;
}

.container {
    width: 100%;
    max-width: 400px;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 15px;
}

.landing .logo {
    width: 240px;
    height: 93px;
    background-image: url(/public/img/landing.svg);
}

.welcome {
    width: 90%;
    height: 210px;
    background-image: url(/public/img/welcome.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.container .blocks {
    margin: 40px 35px;
}

.container .blocks .block {
    color: #FFFFFF;
}

.container .blocks .block:not(:last-of-type) {
    margin-bottom: 24px;
}

.container .blocks .block .title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;

    margin-bottom: 15px;
}

.container .blocks .block .description {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    text-align: center;

    color: #FFFFFF;
}

.access {
    width: 100%;

    font-family: customFont;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    color: #1F1F1F;

    background: #F0F0F0;
    border: none;
    border-radius: 4px;

    padding: 14px;
    margin-bottom: 25px;
}



.paid-label {
    font-size: 37px;
    color: #97F082;
    margin-top: 10px;
}

.ccl-label {
    font-size: 37px;
    color: #d52b2b;
    margin-top: 10px;
}